import React from "react";
import { VpAccordion } from "@vtmn-play/react";

function FAQ({ accordionItems }) {
  // NOTE -- AVOID using dangerouslySetInnerHTML. Only use dangerouslySetInnerHTML with trusted and sanitized data.
  // Here, we are getting data from phraseapp
  return (
    <div className="vtmn-p-3">
      <VpAccordion>
        {accordionItems.map((item, index) => (
          <>
            <VpAccordion.Item key={index + 1} value={`item-${index + 1}`}>
              <VpAccordion.ItemHeader>
                <VpAccordion.ItemHeader.Label>
                  {item.title}
                </VpAccordion.ItemHeader.Label>
                <VpAccordion.ItemHeader.Icon />
              </VpAccordion.ItemHeader>
              <VpAccordion.ItemPanel>
                <p>{item.content}</p>
              </VpAccordion.ItemPanel>
            </VpAccordion.Item>
            {index !== accordionItems?.length - 1 && <VpAccordion.Divider />}
          </>
        ))}
      </VpAccordion>
    </div>
  );
}

export default FAQ;
