import { Route, Routes, useLocation } from "react-router-dom";
import RentalHomepage from ".";
import NotFound from "../../Common/components/NotFound";
import Inspection from "./pages/Inspection/rental-inspection-routes";
import Resources from "./pages/Resources";
import DiscountCode from "./pages/DiscountCode";
import StockAdjustment from "./pages/StockAdjustment";
import Training from "./pages/Training";
import Reports from "./pages/Reporting";
import Layout from "../../Common/layouts";
import { useDispatch } from "react-redux";
import { AppType } from "../../Common/Constants";
import { setActiveAppType } from "../../Redux/CommonSlices/userStoreSlice";

const RentalApp = () => {
  const dispatch = useDispatch();
  let { pathname: currentPathName } = useLocation();
  // Ensure the currentPathName starts with a slash
  if (!currentPathName.startsWith("/")) {
    currentPathName = "/" + currentPathName;
  }
  // Based on pathname get AppType value to be sent to backend and also store in the redux.
  const appType = AppType[currentPathName.split("/")[1]];
  dispatch(setActiveAppType(appType));
  return (
    <Layout>
      <Routes>
        <Route index element={<RentalHomepage />} />
        <Route path="inspection/*" element={<Inspection />} />
        <Route path="resources" element={<Resources />} />
        <Route path="discount-code" element={<DiscountCode />} />
        <Route path="stock-adjustment" element={<StockAdjustment />} />
        <Route path="training" element={<Training />} />
        <Route path="reports" element={<Reports />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default RentalApp;
