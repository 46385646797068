import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useEffect, useRef, useState } from "react";
import { fetchDataWithHeaders } from "./APIUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  addPDIAssets,
  removePDIAsset,
  resetPDIAssets,
} from "../../Apps/PDI/redux/slices/PDISlice";
import {
  addRentalAssets,
  removeRentalAsset,
  resetRentalAssets,
} from "../../Apps/Rental/redux/slices/RentalSlice";
import { FormattedMessage, useIntl } from "react-intl";

const getAccessibleUrl = async (file) => {
  const signedURLObj = await fetchDataWithHeaders(
    `/api/v1/assets/signed_url/new?ext=.${file?.fileExtension}`, // URL
    { "Content-Type": file?.fileType } // Header
  );
  if (signedURLObj?.status === 200) {
    const uploadResp = await uploadFile(signedURLObj?.body?.url, file);
    if (uploadResp.status === 200) {
      return signedURLObj?.body?.accessible_url;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const uploadFile = async (signedUrl, file) => {
  const uploadResponse = await fetch(signedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file?.fileType, // Use the file's MIME type
    },
    body: file?.file,
  });
  return uploadResponse;
};

const ImageHandler = () => {
  const [errors, setErrors] = useState([]);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType
  );
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    userStore: { activeAppType },
    pdi: { assets: PDIAssets },
    rental: { assets: rentalAssets },
  } = useSelector((state) => state);
  const pondRef = useRef(null);

  useEffect(() => {
    const uploadedAssetsLength =
      activeAppType === "PDI"
        ? PDIAssets.length
        : activeAppType === "RENTAL"
        ? rentalAssets.length
        : null;

    setIsUploadComplete(
      uploadedAssetsLength === pondRef.current.getFiles().length
    );
  }, [pondRef, PDIAssets, rentalAssets, activeAppType]);
  return (
    <>
      {!isUploadComplete && (
        <h6 className="vtmn-m-auto vtmn-text-sm vtmn-w-4/5 vtmn-text-center">
          <FormattedMessage id="pdi.inspection.image-uploader.uploading" />
        </h6>
      )}
      {errors?.length ? (
        <h6 className="vtmn-text-background-alert vtmn-m-auto vtmn-text-sm vtmn-w-4/5 vtmn-text-center">
          <FormattedMessage
            id={"quality-control.app.image-upload-error"}
            values={{ filenames: errors?.join(", ") }}
          />
        </h6>
      ) : null}
      <FilePond
        ref={pondRef}
        stylePanelAspectRatio="3:2"
        name="file-uploader"
        required={false}
        disabled={false}
        //   captureMethod={}
        allowDrop={false}
        allowBrowse={true}
        allowPaste={false}
        allowMultiple={true}
        allowRevert={true}
        allowRemove={true}
        allowProcess={true}
        acceptedFileTypes={["image/*"]}
        // files={files}
        maxFiles={20}
        labelIdle={intl.formatMessage({
          id: "pdi.inspection.image-uploader.label",
        })}
        checkValidity={true}
        itemInsertLocation={"after"}
        oninit={() => {
          const resetAssetsFn =
            activeAppType === "PDI"
              ? resetPDIAssets
              : activeAppType === "RENTAL"
              ? resetRentalAssets
              : null;
          dispatch(resetAssetsFn());
        }}
        onaddfile={async (error, file) => {
          setErrors([]);
          if (!error) {
            const accessibleUrl = await getAccessibleUrl(file);
            if (accessibleUrl) {
              const uploadFn =
                activeAppType === "PDI"
                  ? addPDIAssets
                  : activeAppType === "RENTAL"
                  ? addRentalAssets
                  : null;
              dispatch(
                uploadFn({
                  id: file?.id,
                  attachment: accessibleUrl,
                })
              );
            } else {
              setErrors((prevErrors) => [...prevErrors, file?.filename]);
              setIsUploadComplete(true);
              pondRef.current.removeFile(file);
              return;
            }
          } else {
            setErrors((prevErrors) => [...prevErrors, file?.filename]);
            setIsUploadComplete(true);
            pondRef.current.removeFile(file);
            return;
          }
        }}
        onremovefile={(error, file) => {
          if (!error) {
            const removeFn =
              activeAppType === "PDI"
                ? removePDIAsset
                : activeAppType === "RENTAL"
                ? removeRentalAsset
                : null;
            dispatch(
              removeFn({
                id: file?.id,
              })
            );
          }
          // else {
          //   pondRef.current.addFile(file.file);
          //   return;
          // }
        }}
        // onerror={(error, file, status) => {
        //   pondRef.current.removeFile(file);
        //   return;
        // }}
        // onaddfilestart={(error, file) => {}}
        onwarning={(_error, file) => {
          setErrors((prevErrors) => [
            ...prevErrors,
            file?.map((file) => file?.name).join(", "),
          ]);
        }}
        // onupdatefiles={setFiles}
        // beforeAddFile={ (file) => {}}
        // onaddfileprogress={(file) => {}}
        // beforeDropFile={async (file) => {}}
        // beforeRemoveFile={(file) => {}}
      />
    </>
  );
};

export default ImageHandler;
