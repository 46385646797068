import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "../../Common/components/ErrorBoundry";
import Inspection from "./pages/Inspection/pdi-inspection-routes";
import Reporting from "./pages/Reporting";
import Training from "./pages/Training";
import Resources from "./pages/Resources";
import PDIHomepage from ".";
import NotFound from "../../Common/components/NotFound";
import Layout from "../../Common/layouts";
import { useLocation } from "react-router-dom";
import { AppType } from "../../Common/Constants";
import { useDispatch } from "react-redux";
import { setActiveAppType } from "../../Redux/CommonSlices/userStoreSlice";
const PDIApp = () => {
  const dispatch = useDispatch();
  let { pathname: currentPathName } = useLocation();
  // Ensure the currentPathName starts with a slash
  if (!currentPathName.startsWith("/")) {
    currentPathName = "/" + currentPathName;
  }
  // Based on pathname get AppType value to be sent to backend and also store in the redux.
  const appType = AppType[currentPathName.split("/")[1]];
  dispatch(setActiveAppType(appType));
  return (
    <Layout>
      <Routes>
        <Route index element={<PDIHomepage />}></Route>
        <Route
          path="inspection/*"
          element={<Inspection />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="reports"
          element={<Reporting />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="training"
          element={<Training />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route
          path="resources"
          element={<Resources />}
          errorElement={
            <Layout>
              <ErrorBoundary />
            </Layout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default PDIApp;
