import { VpButton, VpInput, VpFormControl, VpIcon } from "@vtmn-play/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import { getCookie } from "../../../../Common/utils/cookie.js";
import { fetchDataWithHeaders } from "../../../../Common/utils/APIUtils.js";
import { checkboxCircle } from "../../../../Static/images/icons.js";
import ProgressBar from "../../../../Common/components/ProgressBar/index.jsx";
import { resetPDIQuestionnaire } from "../../redux/slices/PDISlice.js";
import { PrintContent } from "../../../../Common/utils/printUtils.js";

const PDIInspectionComplete = () => {
  let navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [inputBlurred, setInputBlurred] = useState(false);
  const {
    inspectionID,
    inspectionInfo: { purchaseChannel },
    responses,
    ...questionnaireInfo
  } = useSelector((state) => state.pdi);
  const { userStoreInfo } = useSelector((state) => state.userStore);
  const userInfo = getCookie("userInfo");

  useEffect(() => {
    // Clear questionnaire form if User moved away from final form
    return () => {
      dispatch(resetPDIQuestionnaire());
    };
  }, []);

  const handlePrintCertificate = () => {
    const filteredRadioResponses = responses.filter(
      (response) => response?.acceptable_response_options?.radio === true
    );

    const printWindow = window.open("", "_blank");
    let new_responses = filteredRadioResponses.map((response) => ({
      ...response,
      translated_text: intl.formatMessage({ id: `${response.question_text}` }),
    }));
    const printContent = PrintContent(
      userInfo,
      userStoreInfo,
      questionnaireInfo,
      new_responses
    );
    printWindow.document.write(`
      <html>
        <head>
          <title>Certificate - ${questionnaireInfo?.itemInfo?.orderNumber}</title>
          <script>
            window.addEventListener('afterprint', function() {
              window.close();
            });
          </script>
          <style>
            body {
              margin: 40px;
              min-width: 210mm; 
              font-family: 'Roboto', sans-serif; /* Use Roboto font */
            }

            @media print {
              @page {
                size: A4;
                margin: 10;
              }
            }
            @media print and (orientation: landscape) {
              @page {
                size: A4 landscape;
              }
            }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);

    // Delay closing the document and triggering print so that it loads all assets
    setTimeout(() => {
      printWindow.document.close();
      printWindow.print();
    }, 1000);
  };
  const sendUserEmail = async (inspectionID, userEmail) => {
    const data = await fetchDataWithHeaders(
      `/api/v1/inspections/${inspectionID}`, // URL
      { "Content-Type": "application/json", "Cache-Control": "no-cache" }, // Header
      { method: "PATCH" }, // Method
      {}, // Options
      {
        customer_email: userEmail, // Body
      }
    );
    if (data?.status === 200) {
      setIsEmailSent(true);
    } else {
      setIsEmailSent(false);
      alert("Failed to send email. Please retry");
    }
  };

  const isValidEmail = (val) => {
    // return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(val);
  };
  return (
    <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
      <h1 className="vtmn-font-bold vtmn-text-2xl">
        <FormattedMessage id="pdi.form.inspection.complete.title" />
      </h1>
      <ProgressBar completion={"100"} />
      <section className="vtmn-my-3">
        <div className="card__items vtmn-flex vtmn-mt-6 vtmn-flex-wrap vtmn-items-center vtmn-justify-center">
          <div
            className="card__item  vtmn-py-2
            vtmn-flex vtmn-flex-col vtmn-items-center vtmn-justify-center vtmn-text-center
            vtmn-w-full vtmn-h-full gt-tablet:vtmn-w-1/2 gt-small-desktop:vtmn-w-1/4
            vtmn-rounded-xl vtmn-bg-white"
          >
            <div className="card__item--image vtmn-object-contain">
              {checkboxCircle}
            </div>
            <h6 className="vtmn-font-bold">
              <FormattedMessage id="pdi.form.inspection.complete.form-saved" />
            </h6>
          </div>
        </div>

        {purchaseChannel === "ONLINE_ORDER" ? (
          <div
            className="vtmn-flex vtmn-flex-col vtmn-border vtmn-w-1/2 vtmn-m-auto vtmn-font-bold vtmn-py-5 vtmn-cursor-pointer vtmn-border-background-tertiary vtmn-items-center vtmn-shadow-200 vtmn-rounded-100"
            onClick={() => handlePrintCertificate()}
            onKeyDown={() => {}}
          >
            <span
              aria-hidden="true"
              className="vtmx-printer-line vtmn-w-min vtmn-text-3xl "
            ></span>
            <FormattedMessage id="pdi.form.inspection.complete.print" />
          </div>
        ) : (
          <div className="vtmn-m-auto vtmn-pt-6 vtmn-pb-8 ">
            {!isEmailSent && (
              <>
                <div className="vtmn-flex vtmn-pb-2">
                  <b>
                    <FormattedMessage id="pdi.form.inspection.complete.email_to_customer" />
                  </b>
                </div>
                <form
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <VpFormControl>
                    <div className="vtmn-flex vtmn-justify-between">
                      <div className="vtmn-flex-1 vtmn-pr-2">
                        <VpInput
                          status={
                            inputBlurred && isEmailValid
                              ? "success"
                              : inputBlurred && !isEmailValid
                              ? "error"
                              : "success"
                          }
                          className="vtmn-w-full"
                          name="emailaddress"
                          identifier="vtmn-input"
                          placeholder={intl.formatMessage({
                            id: "pdi.form.inspection.email-address-label",
                          })}
                          onChange={(e) => {
                            setInputBlurred(true);
                            setIsEmailValid(isValidEmail(e.target.value));
                            setUserEmail(e.target.value);
                          }}
                        />
                      </div>
                      <VpButton
                        iconRight="send-plane-2-line"
                        size="medium"
                        variant="primary"
                        disabled={!isEmailValid}
                        onClick={() => sendUserEmail(inspectionID, userEmail)}
                      >
                        <FormattedMessage id="email-send" />
                      </VpButton>
                    </div>

                    {inputBlurred && !isEmailValid && (
                      <VpFormControl.Error>
                        <FormattedMessage id="pdi.form.inspection.email-missing" />
                      </VpFormControl.Error>
                    )}
                  </VpFormControl>
                </form>
              </>
            )}
            {isEmailSent && (
              <div className="vtmn-text-center vtmn-text-green-dark-1 vtmn-py-2">
                <FormattedMessage id="pdi.form.inspection.complete.email-sent" />
              </div>
            )}
          </div>
        )}
      </section>

      <div className="vtmn-py-5">
        <VpButton
          className="vp-button vtmn-btn_size--stretched"
          variant="primary"
          onClick={() => {
            dispatch(resetPDIQuestionnaire());
            navigate("/pdi", { replace: true });
          }}
        >
          <FormattedMessage id="pdi.nav.go-home" />
        </VpButton>
        <div className="vtmn-py-3">
          <VpButton
            className="vp-button vtmn-btn_size--stretched"
            variant="secondary"
            onClick={() => {
              dispatch(resetPDIQuestionnaire());
              navigate("/pdi/inspection", { replace: true });
            }}
          >
            <FormattedMessage id="pdi.nav.new-inspection" />
          </VpButton>
        </div>
      </div>
    </div>
  );
};

export default PDIInspectionComplete;
