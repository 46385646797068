import React, { useState, useRef, useEffect } from "react";
import styles from "./StoreAutocomplete.module.css";
import { VpButton } from "@vtmn-play/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserStore } from "../../../Redux/CommonSlices/userStoreSlice";
import { FormattedMessage } from "react-intl";
import { getCookie } from "../../utils/cookie";
import logout from "../../APIs/logout";
import { useAPI } from "../../utils/APIUtils";
import Layout from "../../layouts";
import { resetPDIQuestionnaire } from "../../../Apps/PDI/redux/slices/PDISlice";
import { handleError, handleLoading } from "../../utils/helpers";
import { resetRentalQuestionnaire } from "../../../Apps/Rental/redux/slices/RentalSlice";

const StoreAutocomplete = () => {
  const dispatch = useDispatch();

  // RESET PDI AND RENTAL SAVED DATA
  useEffect(() => {
    dispatch(resetPDIQuestionnaire());
    dispatch(resetRentalQuestionnaire());
  }, [dispatch]);

  const { userStoreInfo } = useSelector((state) => state.userStore);
  const [searchTerm, setSearchTerm] = useState("");
  const [chooseStore, setChooseStore] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filtered, setFiltered] = useState(null);

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const iconRef = useRef(null);
  const userInfo = getCookie("userInfo");

  const { data, error, isLoading } = useAPI("/api/v1/stores?itemsPerPage=1000");
  useEffect(() => {
    setSearchTerm(userStoreInfo?.store_name);
    setFiltered(data?.items);
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [data, userStoreInfo]);

  const handleSubmitPersonalDetails = (e) => {
    if (!searchTerm) {
      e.preventDefault();
      return setChooseStore(false);
    }
    navigate("/home");
  };
  let navigate = useNavigate();

  const handleChangeUser = () => {
    logout(userInfo?.pisri);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered_list = value
      ? data?.items?.filter((store) =>
          store.store_name.toLowerCase().includes(value.toLowerCase())
        )
      : data?.items;
    setFiltered(filtered_list);
    setChooseStore(true);
  };

  const handleStoreClick = (store) => {
    const { created_at, updated_at, ...rest } = store;
    dispatch(selectUserStore({ ...rest }));
    setSearchTerm(store.store_name);
    setIsDropdownVisible(false);
    setChooseStore(true);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible((prevVisible) => !prevVisible);
  };
  if (!data && isLoading) return <Layout>{handleLoading()}</Layout>;
  if (!data && error) return <Layout>{handleError()}</Layout>;
  if (data) {
    return (
      <Layout>
        <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
          <h1 className="vtmn-font-bold vtmn-text-2xl">
            <FormattedMessage id="pdi.form.personal-details.title" />
          </h1>
          <>
            <div className="vtmn-relative vtmn-mt-5">
              <label>
                <FormattedMessage id="pdi.form.personal-details.select-store" />
              </label>
              <span
                ref={iconRef}
                aria-hidden="true"
                style={{ zIndex: 1 }}
                className={`vtmn-absolute vtmn-top-7 vtmn-right-0 vtmn-10-3 vtmn-px-5 ${
                  isDropdownVisible
                    ? "vtmx-chevron-up-line"
                    : "vtmx-chevron-down-line"
                }`}
                onClick={toggleDropdown}
              ></span>

              <input
                ref={inputRef}
                type="search"
                placeholder="Auto populate store"
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => setIsDropdownVisible(true)}
                className={`${styles.autocompleteInput} vp-input vtmn-w-full autocompleteInput vtmn-border-2`}
              />
              {data && !chooseStore ? (
                <p className="vtmn-text-danger">
                  <FormattedMessage id="pdi.form.inspection.store-name-missing" />
                </p>
              ) : null}

              {isDropdownVisible && (
                <ul
                  className={`${styles.dropDownItems} vtmn-dropdown_items`}
                  ref={dropdownRef}
                >
                  {filtered?.length
                    ? filtered?.map((store) => (
                        <li
                          className={styles.dropDownItem}
                          key={store.store_name}
                          onClick={() => handleStoreClick(store)}
                        >
                          {store.store_name}
                        </li>
                      ))
                    : "No results"}
                </ul>
              )}
            </div>
            <div className="vtmn-py-6">
              <label>
                <FormattedMessage id="pdi.form.personal-details.confirm-name" />
              </label>
              <p className="vtmn-my-2 vtmn-font-bold">
                {userInfo?.displayName}
              </p>
            </div>
            <div className="vtmn-pb-4">
              <label>
                <FormattedMessage id="pdi.form.personal-details.confirm-email" />
              </label>
              <p className="vtmn-my-2 vtmn-font-bold">{userInfo?.mail}</p>
            </div>
          </>

          <div className="vtmn-py-2">
            <VpButton
              className="vp-button vtmn-btn_size--stretched"
              variant="primary"
              iconRight="chevron-right-line"
              onClick={handleSubmitPersonalDetails}
              disabled={!userStoreInfo}
            >
              <FormattedMessage id="pdi.nav.continue" />
            </VpButton>
          </div>
          <div>
            <VpButton
              className="vp-button vtmn-btn_size--stretched"
              variant="secondary"
              iconRight="chevron-right-line"
              onClick={handleChangeUser}
            >
              <FormattedMessage id="pdi.nav.change-user" />
            </VpButton>
          </div>
        </div>
      </Layout>
    );
  }
};

export default StoreAutocomplete;
